@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";
@use "../../../../common/scss/font";

.aboTrackingTile {
  display: flex;
  flex-direction: row;
  border: 1px solid colors.$light_gray;
  border-radius: 0.75rem;
  transition: border-color 300ms ease-out;
  padding: 1rem;
  margin: 1rem 0;
  overflow-x: hidden;

  @include mixins.md_l {
    margin: 0.5rem 0;
  }

  &__group {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: calc(100% - 2rem);
  }

  &__requirements {
    &--mobile {
      padding-top: 0.5rem;
    }
  }

  &__incentiveTags,
  &__aboName,
  &__aboNum {
    display: flex;
    justify-content: space-between;
    line-height: 1.5rem;
  }

  &__aboNum {
    font-weight: font.$weight--regular;
  }

  &__aboNameNum {
    display: flex;
    gap: 0.25rem;
  }

  &__cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.5rem;
    padding-right: 0.5rem;
    flex: 0 1 20%;
    min-width: 5rem;

    &--name {
      flex: 0 2 30%;
      min-width: 10rem;
      flex-wrap: wrap;
    }

    &--tags {
      flex: 0 1 15%;
      min-width: 8rem;
      flex-wrap: wrap;
    }

    &--progress {
      flex: 0 1 25%;
      min-width: 8rem;
    }

    &--eligibility {
      flex: 0 2 10%;
    }
  }

  &__incentiveTags {
    color: colors.$dark_grey_on_white;
    font-weight: 400;

    @include mixins.md_l {
      margin-bottom: 0.3125rem;
    }

    &--mobile {
      padding-bottom: 0.75rem;
    }
  }

  &__eligiblityTag {
    &--mobile {
      color: colors.$dark_grey_on_white;
      font-weight: 600;
    }
  }

  &__aboName {
    font-weight: 600;
  }

  &__infoTags {
    display: flex;
    flex-flow: row wrap;
    gap: 0.65rem 0.25rem;

    @include mixins.md_l {
      margin-top: 1rem;
    }
  }

  &__arrow {
    width: 1rem;
    height: 100%;
    place-self: center center;
    display: flex;
  }
}

.aboTrackingTile,
.aboTrackingTile * {
  cursor: pointer !important;
}

.aboTrackingTile:hover {
  border-color: colors.$blue;
  transition: border-color 300ms ease-in;
}
