@use "../../scss/colors";
@use "../../scss/font";
@use "../../scss/layers";

.leaf-trend-indicator {
  display: flex;
  align-items: center;

  &__trend-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.125rem 0.25rem;
    gap: 0.25rem;
    border: 0.063rem;
    border-radius: 0.25rem;
    height: 1.375rem;
    min-width: 2.063rem;
  }

  &__trend-number {
    line-height: 1.125rem;
    min-width: 0.563rem;
    font-size: font.$size--medium;
  }

  &__green {
    background-color: colors.$light_green_8;
    color: colors.$light_green;
  }

  &__red {
    background-color: colors.$background_light_red;
    color: colors.$error_red;
  }

  &__grey {
    background-color: colors.$amway_white;
    color: colors.$dark_grey_on_white;
  }
}
