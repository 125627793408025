@use "../../scss/colors";

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-2px);
  }

  60% {
    transform: translateY(0);
  }
}

%iconBase {
  display: flex;
  flex: 1;
  transition: rotate 0s;
}

.icon-growth_indicator {
  display: block;
  height: 100%;
  width: 100%;

  &__animate {
    animation: bounce 2s ease;
  }

  &__up {
    @extend %iconBase;

    rotate: z 0deg;
  }

  &__down {
    @extend %iconBase;

    rotate: z -180deg;
  }

  &__right {
    @extend %iconBase;

    rotate: z -270deg;
  }

  &__left {
    @extend %iconBase;

    rotate: z -90deg;
  }

  &__green > * {
    fill: colors.$light_green;
  }

  &__red > * {
    fill: colors.$error_red;
  }

  &__grey > * {
    fill: colors.$dark_grey_on_white;
  }
}
