@use "../../scss/colors";

.LeafIconButton {
  &__button {
    border: none;
    background: none !important;
    display: flex;
    align-self: center;
    cursor: pointer;
    width: auto;

    &:hover {
      transition: all 2s ease-in-out;
      cursor: pointer !important;

      svg,
      path,
      rect {
        cursor: pointer !important;
      }
    }

    &:disabled {
      cursor: default !important;

      svg,
      path,
      rect,
      span,
      * >,
      ~ {
        cursor: default !important;
      }

      opacity: 0.2;
      color: colors.$disabled !important;
    }
  }
}
