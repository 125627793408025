@use "../../../../common/scss/colors";
@use "../../../../common/scss/mixins";

.GraduatedTile {
  display: flex;
  flex: 0 1 100%;
  flex-grow: 1;
  flex-direction: row;
  border: 1px solid colors.$light_gray;
  border-radius: 0.75rem;
  transition: border-color 300ms ease-out;
  padding: 0.8125rem 1.5rem;
  margin: 1rem 0;

  @include mixins.md_l {
    margin: 0.5rem 0;
  }

  &__group {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__graduatedIn {
    margin-top: 1rem;
  }

  &__incentiveTags,
  &__aboName,
  &__aboNum {
    display: flex;
    justify-content: space-between;
    line-height: 1.5rem;
  }

  &__aboNameNum {
    display: flex;
    gap: 0.25rem;
  }

  &__cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.5rem;
    padding-right: 0.5rem;
    flex: 0 1 20%;
    min-width: 5rem;

    &--name {
      flex: 0 2 30%;
      min-width: 10rem;
      flex-wrap: wrap;
    }

    &--progress {
      flex: 0 1 15%;
      min-width: 8rem;
    }

    &--eligibility {
      flex: 0 2 10%;
    }

    &--mobileStatus {
      color: colors.$dark_grey_on_white;
      display: flex;
      justify-content: space-between;
    }
  }

  &__incentiveTags {
    color: colors.$dark_grey_on_white;
    font-weight: 400;

    @include mixins.md_l {
      margin-bottom: 0.3125rem;
    }
  }

  &__aboName {
    font-weight: 600;
  }

  &__infoTags {
    display: flex;
    flex-flow: row wrap;
    gap: 0.65rem 0.25rem;
    flex-wrap: wrap;

    @include mixins.md_l {
      margin-top: 1rem;
    }
  }

  &__arrow {
    width: 1rem;
    height: 100%;
    place-self: center center;
    display: flex;
    margin-left: auto;
  }
}

.GraduatedTile,
.GraduatedTile * {
  cursor: pointer !important;
}

.GraduatedTile:hover {
  border-color: colors.$blue;
  transition: border-color 300ms ease-in;
}
